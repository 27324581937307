import React, { useRef, useEffect, useState } from 'react';

const Floating = (props) => {
  const canvasRef = useRef(null);
  const colourArray = ['rgb(255,255,255,1)', '#3E5288', '#2E5965'];
  let circleArray = [];

  useEffect(() => {
    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = 960;
    let middleX = canvas.width / 2;
    const mouse = {
      x: 0,
      y: 440
    };

    let scaledMouseX = 0.1;

    window.addEventListener('mousemove', (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
      scaledMouseX = (mouse.x / window.innerWidth) *4 - 2;

    });

    window.addEventListener("resize", () => {
      canvas.width = window.innerWidth;
      middleX = canvas.width / 2;
    });
    

    function Circle(x, y, dx, dy, radius) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.radius = radius;
      this.minRadius = radius;
      this.colour = colourArray[Math.floor(Math.random() * colourArray.length)];
      this.opacity = 0;

      this.draw = function () {
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        c.fillStyle = `rgb(255, 255, 255, ${this.opacity})`;
        c.fill();
      };

      this.update = function () {
        this.x += this.dx;
        this.y += this.dy;
        if (this.radius > 0.012) {
          this.radius -= 0.012;
        }

        if (this.opacity < 0.7){
          this.opacity += 0.02;
        }

        this.draw();
      };
    }

    let frame = 0;
  
    function animate() {
      requestAnimationFrame(animate);
      frame++;
      if (frame === 1000) {
        frame = 0;
      }

      // Filter and remove particles that are out of view or too small
      circleArray = circleArray.filter(circle => {
        return circle.y + circle.radius > 0 && circle.radius > 0.1;
        
      });
      // console.log(circleArray.length);

      const circleCenterX = middleX; // X-coordinate of the circle's center
      const circleCenterY = canvas.height / 2; // Y-coordinate of the circle's center
      let circleRadius = 160; // Radius of the circular distribution
      const circleDensity = 50;

      if(canvas.width > 768){
        circleRadius = 200;
      }
    
      for (let i = 0; i < circleDensity; i++) {
        if (frame % 10 === 0) {

          let scaledY = circleCenterY - mouse.y;
          if (scaledY > 252) {
            scaledY = 252;
          }else if (scaledY < -252){
            scaledY = -252;
          }
          scaledY = scaledY / 252;
          const angle = (i / circleDensity) * Math.PI * 2; // Calculate angle around the circle
          const x = circleCenterX + circleRadius * Math.cos(angle);
          const y = circleCenterY + circleRadius * Math.sin(angle);
          const dx = scaledMouseX;
          const dy = (-1*(((Math.random() * 0.4)) + scaledY));
          const radius = Math.random() * 2 + 1.5;
          circleArray.push(new Circle(x, y, dx, dy, radius));
        }
      }

      c.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < circleArray.length; i++) {
        circleArray[i].update();
      }
    }
    animate();
  }, []);
  // console.log(props.threeDNPosition.y);


  return (

          <canvas className='floating__canvas' ref={canvasRef}></canvas>

    );
};

export default Floating;
